import './FAQ.css';
import React, { useState } from 'react';

const FAQ = () => {
	const [selected, setSelected] = useState(null);
	const toggle = (index) => {
		if (selected === index) {
			return setSelected(null);
		}
		setSelected(index);
	};

	return (
		<div className='faq__container'>
			<div>
				<h2 className='title'>FAQ's</h2>
			</div>

			<div className='wrapper'>
				<div className='accordion-faq'>
					{dataCollection.map((item, index) => (
						<div
							key={index}
							data-aos='fade-up'
							className={`item aos-init aos-animate ${
								selected === index ? 'selected' : ''
							}`}
						>
							<div
								className='accordion__faq-heading'
								onClick={() => toggle(index)}
							>
								<h2>{item.question}</h2>
								{
									<div>
										{selected === index ? (
											<>
												<span className='verticle'>
													-
												</span>
											</>
										) : (
											<>
												<span className='verticle'>
													+
												</span>
											</>
										)}
									</div>
								}
							</div>
							<div
								className={
									selected === index
										? 'content show'
										: 'content'
								}
							>
								<div
									dangerouslySetInnerHTML={{
										__html: item.answer,
									}}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
const dataCollection = [
	{
		question: 'Are there any registration fees?',
		answer: 'This event is completely free of cost!',
	},
	{
		question: 'Can I register as a team?',
		answer: 'Cyberquest is an individual event.',
	},
	{
		question: 'Why should I participate?',
		answer: 'Participate in CTF challenges to sharpen cybersecurity skills, solve real-world problems, and join a collaborative community. Gain hands-on experience and add a dynamic edge to your resume while enjoying the thrill of solving engaging cybersecurity puzzles.',
	},
	{
		question: 'How are winners decided?',
		answer: 'The top three people from the leaderboard will be awarded prices when the event ends.',
	},
	{
		question: 'I have more questions.',
		answer: 'Feel free to reach out to support@excelmec.org',
	},
];

export default FAQ;
