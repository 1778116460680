import { useContext, useState } from 'react';
import { ApiContext } from '../../contexts/api/ApiContext';
import { getErrMsg } from '../errorParser';
import UserContext from '../../contexts/user/UserContext';

export function useLeaderboard() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [leaderboard, setLeaderboard] = useState([]);

	const { axiosCyberQuestPrivate } = useContext(ApiContext);
	const { userData } = useContext(UserContext);

	async function getLeaderboard() {
		try {
			setLoading(true);
			setLeaderboard(new Array(0));

			if (!userData?.loggedIn) {
				return;
			}

			const { data } = await axiosCyberQuestPrivate.get(`/leaderboard`);
			setLeaderboard(data?.leaderboard);

		} catch (error) {
			setError(getErrMsg(error));
		} finally {
			setLoading(false);
		}
	}

	return {
		loading,
		error,
		leaderboard,
		getLeaderboard,
	};
}
