import './StaticBox.css';
import './DynamicBox.css';
import { instanceStatuses } from '../../../hooks/challenges/useChallenge';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ModalPopup } from '../../ModalPopup/ModalPopup';
import { contactNumer } from '../../../utils/contact';

export default function StaticDynamic({
	challengeType,
	staticUrl,

	instanceFetching,
	instanceError,
	instanceStatus,
	instanceIp,
	instanceCreationTime,
	instanceLastUsedTime,
	heartBeatInstance,
	getInstanceInfo,
	createInstance,
	deleteInstance,
}) {
	if (challengeType === 'STATIC') {
		return <StaticBox staticUrl={staticUrl} />;
	} else if (challengeType === 'DYNAMIC') {
		return (
			<DynamicBox
				instanceFetching={instanceFetching}
				instanceError={instanceError}
				instanceStatus={instanceStatus}
				instanceIp={instanceIp}
				instanceCreationTime={instanceCreationTime}
				instanceLastUsedTime={instanceLastUsedTime}
				heartBeatInstance={heartBeatInstance}
				getInstanceInfo={getInstanceInfo}
				createInstance={createInstance}
				deleteInstance={deleteInstance}
			/>
		);
	} else {
		return "OOPS That's an error";
	}
}

function StaticBox({ staticUrl }) {
	return (
		<p className='challenge__static__url__box'>
			<span className='challenge__static__url__box__text'>
				Challenge URL
			</span>
			<span className='challenge__static__url__box__colon'>:</span>
			<a
				href={staticUrl}
				target='_blank'
				rel='noreferrer'
				className='challenge__static__url__link'
			>
				{staticUrl}
			</a>
		</p>
	);
}

function DynamicBox({
	instanceFetching,
	instanceError,
	instanceStatus,
	instanceIp,
	instanceCreationTime,
	instanceLastUsedTime,
	heartBeatInstance,
	getInstanceInfo,
	createInstance,
	deleteInstance,
}) {
	const recaptcha = useRef();
	let lastUsed = '';
	if (instanceLastUsedTime) {
		const lastUsedHour = ('0' + instanceLastUsedTime.getHours()).slice(-2);
		const lastUsedMinute = ('0' + instanceLastUsedTime.getMinutes()).slice(
			-2
		);
		const lastUsedDay = ('0' + instanceLastUsedTime.getDate()).slice(-2);
		const lastUsedMonth = (
			'0' +
			(instanceLastUsedTime.getMonth() + 1)
		).slice(-2);
		lastUsed = `${lastUsedHour}:${lastUsedMinute} ${lastUsedDay}/${lastUsedMonth}`;
	}

	useEffect(() => {
		getInstanceInfo();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (instanceFetching) {
		return (
			<div className='challenge__dynamic__instance__box'>
				<div className='challenge__dynamic__instance__box__header'>
					<HelpIcon />
				</div>
				<div className='challenge__dynamic__instance__box__access'>
					<span className='challenge__dynamic__instance__box__access__status__text'>
						Loading Instance Info...
					</span>
				</div>
			</div>
		);
	}

	if (instanceError) {
		console.error(instanceError);
		return (
			<div className='challenge__dynamic__instance__box'>
				<div className='challenge__dynamic__instance__box__header'>
					<HelpIcon />
				</div>
				<div className='challenge__dynamic__instance__box__access'>
					<span className='challenge__dynamic__instance__box__access__status__text'>
						Error Fetching Instance Info
					</span>
				</div>
			</div>
		);
	}

	function onPowerButtonClick() {
		if (isPowerBtnDisabled(instanceStatus, instanceCreationTime)) {
			return;
		}
		switch (instanceStatus) {
			case instanceStatuses.NOT_CREATED:
				createInstance();
				break;

			case instanceStatuses.RUNNING:
			case instanceStatuses.ERRORED:
			case instanceStatuses.TERMINATED_INACTIVE:
			case instanceStatuses.STARTING:
			case instanceStatuses.STOPPING:
			case instanceStatuses.STOPPING_INACTIVE:
				deleteInstance();
				break;
			default:
				break;
		}
	}

	const { ledColor, ledPulse } = getPowerLedColor(instanceStatus);

	function CaptchaHeartBeatModal() {
		const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
		if (!siteKey) {
			console.error('Captcha Site key not found. Not enforcing captcha');
		}

		return (
			<>
				<div className={`dynamic__heartbeat__modal`}>
					{siteKey && (
						<ReCAPTCHA
							ref={recaptcha}
							sitekey={siteKey}
							onError={(e) => {
								recaptcha.current.reset();
							}}
							onExpired={(e) => {
								recaptcha.current.reset();
							}}
						/>
					)}
					<button
						className='challenge__dynamic__instance__box__heartbeat__button'
						onClick={() => {
							if (!siteKey) {
								heartBeatInstance();
								return;
							}
							const captchaValue = recaptcha.current.getValue();
							if (!captchaValue) {
								alert('Please verify the reCAPTCHA!');
							} else {
								heartBeatInstance(captchaValue);
							}
						}}
					>
						Submit
					</button>
				</div>
				<div
					className={`dynamic__heartbeat__modal__overlay`}
					onClick={() => {
						document
							.getElementsByClassName(
								'dynamic__heartbeat__modal'
							)[0]
							?.classList.remove('heartbeat__open');
						document
							.getElementsByClassName(
								'dynamic__heartbeat__modal__overlay'
							)[0]
							?.classList.remove('heartbeat__open');
					}}
				></div>
			</>
		);
	}
	return (
		<>
			<div className='challenge__dynamic__instance__box'>
				<div className='challenge__dynamic__instance__box__header'>
					<HelpIcon />
				</div>
				<div className='challenge__dynamic__instance__box__power'>
					<div
						className={`challenge__dynamic__instance__box__power__button ${
							isPowerBtnDisabled(
								instanceStatus,
								instanceCreationTime
							)
								? 'powerDisabled'
								: ''
						}`}
						onClick={onPowerButtonClick}
					>
						<span className='challenge__dynamic__instance__box__power__button__text'>
							{getPowerButtonText(instanceStatus)}
						</span>
						<PowerIcon
							className={
								isPowerBtnDisabled(
									instanceStatus,
									instanceCreationTime
								)
									? 'powerDisabled'
									: ''
							}
						/>
					</div>
					<div className='challenge__dynamic__instance__box__power__status_container'>
						<span className='challenge__dynamic__instance__box__power__status_text'>
							{getPowerStatusText(instanceStatus)}
						</span>
						<div
							className='challenge__dynamic__instance__box__power__status_led'
							style={{
								backgroundColor: ledColor,
								boxShadow: `0px 0px 8px 1px ${ledColor}`,
								animation: ledPulse
									? 'pulse 1s ease-in-out infinite alternate'
									: '',
							}}
						></div>
					</div>
				</div>
				<div className='challenge__dynamic__instance__box__access'>
					{getInstanceAccessText(
						instanceStatus,
						instanceIp,
						instanceCreationTime
					)}
				</div>
				{instanceStatus === instanceStatuses.RUNNING && (
					<div className='challenge__dynamic__instance__box__heartbeat'>
						<span className='challenge__dynamic__instance__box__heartbeat__text'>
							<span>Last Used Time</span>
							<span>{lastUsed}</span>
						</span>
						<button
							className='dynamic__heartbeat__open__button'
							onClick={() => {
								document
									.getElementsByClassName(
										'dynamic__heartbeat__modal'
									)[0]
									?.classList.add('heartbeat__open');
								document
									.getElementsByClassName(
										'dynamic__heartbeat__modal__overlay'
									)[0]
									?.classList.add('heartbeat__open');
							}}
						>
							I'm Here
						</button>
					</div>
				)}
			</div>

			<CaptchaHeartBeatModal />
		</>
	);
}

function getPowerButtonText(instanceStatus) {
	switch (instanceStatus) {
		case instanceStatuses.NOT_CREATED:
			return 'Turn ON';
		case instanceStatuses.STARTING:
			return 'Turn OFF';
		case instanceStatuses.RUNNING:
			return 'Turn OFF';
		case instanceStatuses.STOPPING:
			return 'Turn OFF';
		case instanceStatuses.ERRORED:
			return 'Delete Instance';
		case instanceStatuses.STOPPING_INACTIVE:
			return 'Turn OFF';
		case instanceStatuses.TERMINATED_INACTIVE:
			return 'Delete Instance';
		default:
			return 'UNKNOWN';
	}
}

function getPowerLedColor(instanceStatus) {
	const yellow = '#ede958';
	const green = '#28aa47';
	const red = '#e03c3c';
	const grey = '#767676';

	switch (instanceStatus) {
		case instanceStatuses.NOT_CREATED:
			return {
				ledColor: grey,
				ledPulse: false,
			};
		case instanceStatuses.STARTING:
			return {
				ledColor: yellow,
				ledPulse: true,
			};
		case instanceStatuses.RUNNING:
			return {
				ledColor: green,
				ledPulse: false,
			};
		case instanceStatuses.STOPPING:
			return {
				ledColor: yellow,
				ledPulse: true,
			};
		case instanceStatuses.ERRORED:
			return {
				ledColor: red,
				ledPulse: false,
			};
		case instanceStatuses.STOPPING_INACTIVE:
			return {
				ledColor: red,
				ledPulse: true,
			};
		case instanceStatuses.TERMINATED_INACTIVE:
			return {
				ledColor: red,
				ledPulse: false,
			};
		default:
			return {
				ledColor: grey,
				ledPulse: false,
			};
	}
}

function getInstanceAccessText(
	instanceStatus,
	instanceIp,
	instanceCreationTime
) {
	switch (instanceStatus) {
		case instanceStatuses.NOT_CREATED:
			return (
				<span className='challenge__dynamic__instance__box__access__status__text'>
					Turn your Instance ON to access the challenge
				</span>
			);
		case instanceStatuses.STARTING:
			const currDate = new Date();
			const diff = currDate - instanceCreationTime;
			const diffInMins = Math.floor(diff / 1000 / 60);
			if (diffInMins < 5) {
				return (
					<span className='challenge__dynamic__instance__box__access__status__text'>
						Starting. Please wait...
						<br />
						This may take any where between 2-5 mins.
					</span>
				);
			} else {
				return (
					<span className='challenge__dynamic__instance__box__access__status__text'>
						Starting. Please wait...
						<br />
						This is taking longer than expected.
						<br />
						Please delete and create a new instance.
						<br />
						If the problem persists, contact: {contactNumer}
					</span>
				);
			}
		case instanceStatuses.RUNNING:
			return (
				<>
					<span className='challenge__dynamic__instance__box__access__text'>
						Your Instance IP
					</span>
					<span className='challenge__dynamic__instance__box__access__colon'>
						:
					</span>
					<span className='challenge__dynamic__instance__box__access__ip'>
						{instanceIp}
					</span>
				</>
			);
		case instanceStatuses.STOPPING:
			return (
				<span className='challenge__dynamic__instance__box__access__status__text'>
					Stopping. Please wait...
					<br />
					This may take any where between 2-5 mins.
				</span>
			);
		case instanceStatuses.ERRORED:
			return (
				<span className='challenge__dynamic__instance__box__access__status__text'>
					Instance Errored. Please delete and create a new instance
				</span>
			);
		case instanceStatuses.STOPPING_INACTIVE:
			return (
				<span className='challenge__dynamic__instance__box__access__status__text'>
					Stopping due to inactivity. Please wait...
					<br />
					This may take any where between 2-5 mins.
				</span>
			);
		case instanceStatuses.TERMINATED_INACTIVE:
			return (
				<span className='challenge__dynamic__instance__box__access__status__text'>
					Your Instance has been terminated due to inactivity.
					<br />
					Please delete and create a new instance
				</span>
			);
		default:
			return 'UNKNOWN';
	}
}

function isPowerBtnDisabled(instanceStatus, instanceCreationTime) {
	switch (instanceStatus) {
		case instanceStatuses.NOT_CREATED:
		case instanceStatuses.RUNNING:
		case instanceStatuses.ERRORED:
		case instanceStatuses.TERMINATED_INACTIVE:
			return false;

		case instanceStatuses.STOPPING:
		case instanceStatuses.STOPPING_INACTIVE:
			return true;
		case instanceStatuses.STARTING:
			const currDate = new Date();
			const diff = currDate - instanceCreationTime;
			const diffInMins = Math.floor(diff / 1000 / 60);
			if (diffInMins < 5) {
				return true;
			} else {
				return false;
			}

		default:
			return true;
	}
}

function getPowerStatusText(instanceStatus) {
	switch (instanceStatus) {
		case instanceStatuses.NOT_CREATED:
			return 'OFF';
		case instanceStatuses.STARTING:
			return 'STARTING';
		case instanceStatuses.RUNNING:
			return 'RUNNING';
		case instanceStatuses.STOPPING:
			return 'STOPPING';
		case instanceStatuses.ERRORED:
			return 'ERRORED';
		case instanceStatuses.STOPPING_INACTIVE:
			return 'STOPPING';
		case instanceStatuses.TERMINATED_INACTIVE:
			return 'STOPPED';
		default:
			return 'UNKNOWN';
	}
}

function HelpIcon() {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<div
				className='challenge__dynamic__instance__box__icon_wrapper'
				onClick={() => setShowModal(true)}
			>
				<svg
					width='20'
					height='20'
					viewBox='0 0 38 38'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M17.0954 30.3774H20.8438V26.629H17.0954V30.3774ZM18.9696 0.390091C8.62398 0.390091 0.227539 8.78653 0.227539 19.1322C0.227539 29.4778 8.62398 37.8742 18.9696 37.8742C29.3152 37.8742 37.7117 29.4778 37.7117 19.1322C37.7117 8.78653 29.3152 0.390091 18.9696 0.390091ZM18.9696 34.1258C10.7044 34.1258 3.97595 27.3974 3.97595 19.1322C3.97595 10.8669 10.7044 4.1385 18.9696 4.1385C27.2348 4.1385 33.9632 10.8669 33.9632 19.1322C33.9632 27.3974 27.2348 34.1258 18.9696 34.1258ZM18.9696 7.88691C14.8276 7.88691 11.4728 11.2417 11.4728 15.3837H15.2212C15.2212 13.3221 16.908 11.6353 18.9696 11.6353C21.0312 11.6353 22.718 13.3221 22.718 15.3837C22.718 19.1322 17.0954 18.6636 17.0954 24.7548H20.8438C20.8438 20.5378 26.4664 20.0693 26.4664 15.3837C26.4664 11.2417 23.1116 7.88691 18.9696 7.88691Z'
						fill='white'
					/>
				</svg>
			</div>
			<ModalPopup open={showModal} setOpen={setShowModal}>
				<div className='challenge__dynamic__instance__box__help__modal__content'>
					<span>
						This is a dynamic challenge. You will need to
						<span className='instance__help__highlight'>
							{' '}
							TURN ON{' '}
						</span>
						your instance to attempt the challenge.
					</span>

					<span>
						Instances inactive for more than
						<span className='instance__help__highlight'>
							{' '}
							60 mins{' '}
						</span>
						will be automatically terminated.
					</span>

					<span>
						You can use the{' '}
						<span className='instance__help__highlight'>
							{' '}
							I'm Here"{' '}
						</span>{' '}
						button to let us know that you are still using the
						instance. This will reset the inactivity timer.
					</span>

					<span>
						You can have
						<span className='instance__help__highlight'>
							{' '}
							atmost 1 instance{' '}
						</span>
						running at a time across all challenges. Please delete
						your existing instance before creating a new one.
					</span>

					<span>
						If you're facing any issues with your instance, you can
						contact: {contactNumer}
					</span>
				</div>
			</ModalPopup>
		</>
	);
}

function PowerIcon({ className }) {
	return (
		<div
			className={`challenge__dynamic__instance__box__icon_wrapper ${className}`}
		>
			<svg
				width='25'
				height='25'
				viewBox='0 0 39 39'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M21.636 0.334297H17.4155V21.4366H21.636V0.334297ZM31.8284 4.9135L28.8319 7.91002C32.166 10.59 34.2974 14.705 34.2974 19.3264C34.2974 27.493 27.6924 34.098 19.5258 34.098C11.3592 34.098 4.75415 27.493 4.75415 19.3264C4.75415 14.705 6.88548 10.59 10.1985 7.88892L7.22312 4.9135C3.12927 8.39538 0.533691 13.5443 0.533691 19.3264C0.533691 29.8142 9.03792 38.3184 19.5258 38.3184C30.0136 38.3184 38.5178 29.8142 38.5178 19.3264C38.5178 13.5443 35.9223 8.39538 31.8284 4.9135Z'
					fill='white'
				/>
			</svg>
		</div>
	);
}
