import { useEffect } from 'react';
import { useLeaderboard } from '../../../hooks/challenges/useLeaderboard';
import './Leaderboard.css';

export default function Leaderboard() {
	const { loading, error, leaderboard, getLeaderboard } = useLeaderboard();

	useEffect(() => {

		getLeaderboard();
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return (
			<div className='leaderboard__wrapper'>
				<div className='leaderboard__header'>
					<h1>Leaderboard</h1>
				</div>
				<div className='leaderboard__body'>Loading...</div>
			</div>
		);
	}

	if (error) {
		return (
			<div className='leaderboard__wrapper'>
				<div className='leaderboard__header'>
					<h1>Leaderboard</h1>
				</div>
				<div className='leaderboard__body'>{error}</div>
			</div>
		);
	}

	return (
		<div className='leaderboard__wrapper'>
			<div className='leaderboard__header'>
				<h1>Leaderboard</h1>
			</div>
			<div className='leaderboard__body'>
				<div className='leaderboard__user'>
					<div className='leaderboard__user__rank'>Rank</div>
					<div className='leaderboard__user__details'>Name</div>
					<div className='leaderboard__user__points leaderboard__user__points__header'>Total Score</div>
				</div>
				{leaderboard?.length === 0 && (
					<div className='leaderboard__user' style={{
						textAlign: 'center',
						justifyContent: 'center',
					}}>
						Nobody has completed any challenges yet!
					</div>
				)}
				{leaderboard.map((user, index) => {
					return (
						<div className='leaderboard__user' key={index}>
							<div className='leaderboard__user__rank'>
								{index + 1}
							</div>
							<div className='leaderboard__user__details'>
								<img
									className='leaderboard__user__avatar'
									src={user.profilePicUrl}
									rel='noreferrer noopener'
									alt='avatar'
								/>
								<div className='leaderboard__user__name'>
									{user.username}
								</div>
							</div>
							<div className='leaderboard__user__points'>
								{user.totalScore}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
