import { useState, useEffect } from 'react';
import './RegisterPage.css';
import { useRegister } from '../../hooks/useRegister';
import { useInterval } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import ErrorPage from '../../components/Error/ErrorPage';

const RegisterPage = () => {
	const [countdown, setCountdown] = useState('');
	const [delay, setDelay] = useState(0);
	const [countdownRunning, setCountdownRunning] = useState(true);
	const navigate = useNavigate();

	const {
		launched,
		launchDateTime,
		endDateTime,
		registered,
		loading,
		error,
		fetchIfRegistered,
		register,
	} = useRegister();

	const [fromTo, setFromTo] = useState('');

	useEffect(() => {
		const startMonth = launchDateTime.toLocaleString('default', {
			month: 'long',
		});
		const startDay = launchDateTime.getDate();
		const endMonth = endDateTime.toLocaleString('default', {
			month: 'long',
		});
		const endDay = endDateTime.getDate();

		setFromTo(
			`${startMonth} ${ordinal_suffix_of(
				startDay
			)} to ${endMonth} ${ordinal_suffix_of(endDay)}`
		);
	}, [launchDateTime, endDateTime]);

	async function onRegisterClick() {
		if (launched) {
			if (registered) {
				navigate('/challenge');
			} else {
				await register();
				navigate('/challenge');
			}
		} else {
			if (registered) {
				// do nothing
			} else {
				await register();
			}
		}
	}

	useInterval(
		() => {
			if (loading) {
				return;
			}
			if (delay === 0) {
				setDelay(1000);
			}

			const now = new Date();
			const end = new Date(launchDateTime); // Month is 0-indexed (10 = November)
			const distance = end - now;

			// Time calculations for days, hours, minutes and seconds
			const days = Math.floor(distance / (1000 * 60 * 60 * 24));
			const hours = Math.floor(
				(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			const minutes = Math.floor(
				(distance % (1000 * 60 * 60)) / (1000 * 60)
			);
			const seconds = Math.floor((distance % (1000 * 60)) / 1000);

			// Output the result in the countdown timer
			setCountdown(
				`${days}d ${hours}h ${minutes}m ${seconds}s`
			);

			// If the countdown is over, clear the interval
			if (distance < 0) {
				setCountdown('Challenge has started!');
				setCountdownRunning(false);
			}
		},
		countdownRunning ? delay : null
	);

	useEffect(() => {
		fetchIfRegistered();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	if (error) {
		return <ErrorPage message={error} />;
	}

	return (
		<div className='register_wrapper'>
			<div className='register__page_container'>
				<div className='register__text_container'>
					<h1 className='evnt__name_quest1'>Cyber Quest</h1>
					<h2>{loading ? '' : fromTo}</h2>
				</div>
				<div className='countdown'>
					{loading ? 'Loading...' : <div>Starts in:<br/><b>{`${countdown}`}</b></div>}
				</div>

				<button
					className={`reg__btn_1 ${
						registered && !launched ? 'disabled' : ''
					}`}
					to='/register'
					onClick={onRegisterClick}
				>
					{registered
						? launched
							? 'Go to Challenge'
							: 'You have Registered'
						: 'Register'}
				</button>
				{!launched && (
					<p className='reg_annottation'>
						{registered
							? "You'll be notified before the challenge starts"
							: 'Register now to be notified before the challenge starts'}
					</p>
				)}
			</div>
		</div>
	);
};

function ordinal_suffix_of(i) {
	var j = i % 10,
		k = i % 100;
	if (j === 1 && k !== 11) {
		return i + 'st';
	}
	if (j === 2 && k !== 12) {
		return i + 'nd';
	}
	if (j === 3 && k !== 13) {
		return i + 'rd';
	}
	return i + 'th';
}

export default RegisterPage;
