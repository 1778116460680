import './Winners.css';
import prize1 from '../../../assets/png/prize1.jpeg';
import prize2 from '../../../assets/png/prize2.jpeg';
import prize3 from '../../../assets/png/prize3.jpeg';
import { useEffect, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

export default function Winners() {
	const mouseCoordRef = useRef({ x: 0, y: 0 });
	const confettiRef = useRef(null);
	const wrapperRef = useRef(null);

	const mouseMoveHandler = (event) => {
		mouseCoordRef.current = {
			x: event.clientX - wrapperRef.current.offsetLeft,
			y: event.clientY - wrapperRef.current.offsetTop,
		};
	};

	useEffect(() => {
		wrapperRef.current?.addEventListener('mousemove', mouseMoveHandler);

		setTimeout(() => {
			confettiRef.current?.({
				particleCount: 200,
				spread: 200,
				origin: {
					x: 0.5,
					y: 0.7,
				},
			});
		}, 200);

		return () => {

			// eslint-disable-next-line react-hooks/exhaustive-deps
			wrapperRef.current?.removeEventListener(
				'mousemove',
				mouseMoveHandler
			);
		};
	}, []);

	function getConfettiInstance(instance) {
		confettiRef.current = instance;
	}

	function launch() {

		const originX = mouseCoordRef.current.x / wrapperRef.current.offsetWidth;
		const originY = mouseCoordRef.current.y / wrapperRef.current.offsetHeight;

		confettiRef.current?.({
			particleCount: Math.ceil(Math.random() * 100) + 40,
			spread: 70,

			// Range 45 - 135
			angle: Math.ceil(Math.random() * 90) + 45,
			origin: {
				x: originX,
				y: originY
			},
		});
	}

	return (
		<div className='winners__wrapper' onClick={launch} ref={wrapperRef}>
			<ReactCanvasConfetti
				style={{
					boxSizing: 'border-box',
					position: 'absolute',
					width: '100%',
					height: '100%',
					zIndex: -1,
				}}
				refConfetti={getConfettiInstance}
			/>
			<h1 className='winners__header'>Winners!!</h1>
			<div className='winners__prize__box'>
				<div className='winners__prizes'>
					<img src={prize2} alt='prize2' />
					<span className='winners__name'>Nishanth</span>
				</div>

				<div className='winners__prizes winners__prize__1'>
					<img src={prize1} alt='prize1' />
					<span className='winners__name'>Vasanth R</span>
				</div>

				<div className='winners__prizes'>
					<img src={prize3} alt='prize3' />
					<span className='winners__name'>Vyshnav S.D</span>
				</div>
			</div>
		</div>
	);
}
