import ChallengeView from '../../components/Challenge/ChallengeView/ChallengeView';
import SideBar from '../../components/Challenge/Sidebar/SideBar';
import ProtectedRoute from '../../components/ProtectedRoute/ProtectedRoute';
import { Routes, Route, Navigate } from 'react-router-dom';

import './ChallengePage.css';
import Instructions from '../../components/Challenge/Instructions/Instructions';
import Leaderboard from '../../components/Challenge/Leaderboard/Leaderboard';
import Winners from '../../components/Challenge/Winners/Winners';
import { gameOver } from '../../utils/gameOver';

export default function ChallengePage() {
	return (
		<div className='challenge_wrapper'>
			<SideBar />
			<div className='challenge__content__wrapper'>
				<Routes>
					{gameOver ? (
						<Route
							path=''
							element={<Navigate to='winners' replace={true} />}
						/>
					) : (
						<Route
							path=''
							element={
								<Navigate to='instructions' replace={true} />
							}
						/>
					)}

					{!gameOver && (
						<Route path='instructions' element={<Instructions />} />
					)}

					<Route
						path='leaderboard'
						element={
							<ProtectedRoute>
								<Leaderboard />
							</ProtectedRoute>
						}
					/>
					<Route
						path='winners'
						element={
							<ProtectedRoute>
								<Winners />
							</ProtectedRoute>
						}
					/>

					{!gameOver && (
						<Route
							path=':challengeId'
							element={
								<ProtectedRoute>
									<ChallengeView />
								</ProtectedRoute>
							}
						/>
					)}

					{gameOver && (
						<Route
							path='*'
							element={<Navigate to='winners' replace={true} />}
						/>
					)}
				</Routes>
			</div>
		</div>
	);
}
