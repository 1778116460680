import Hints from '../Hints/Hints';
import StaticDynamic from '../StaticDynamic/StaticDynamic';
import SubmitFlag from '../SubmitFlag/SubmitFlag';
import './ChallengeView.css';
import { useParams } from 'react-router-dom';
import { useChallenge } from '../../../hooks/challenges/useChallenge';
import ErrorPage from '../../Error/ErrorPage';
import { useEffect } from 'react';
import PointsModal from '../PointsModal/PointsModal';

export default function ChallengeView() {
	const { challengeId } = useParams();

	const {
		loading,
		error,

		launchDateTime,
		challengeName,
		challengeDesc,
		hints,

		challengePoints,
		submitOrderBonus,
		timeHourlyBonus,

		submittedFlag,
		myChallengePoint,
		myTimeBonusPoint,
		mySubmissionOrderBonusPoint,
		submittingFlag,
		submitFlag,

		staticUrl,

		challengeType,
		instanceFetching,
		instanceError,
		instanceStatus,
		instanceIp,
		instanceCreationTime,
		instanceLastUsedTime,
		heartBeatInstance,
		getInstanceInfo,
		createInstance,
		deleteInstance,

		getChallengeInfo,
	} = useChallenge(challengeId);

	useEffect(() => {
		getChallengeInfo();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [challengeId]);

	if (loading) {
		return <div className='challenge__view__page'>Loading...</div>;
	}

	if (error) {
		console.error(error);
		return <ErrorPage message={error} />;
	}

	const currDate = new Date();
	if (launchDateTime > currDate) {
		const Hour = launchDateTime.getHours();
		const Hour12 = ('0' + ((Hour + 24) % 12 || 12)).slice(-2);
		const ampm = Hour >= 12 ? 'pm' : 'am';
		const Minute = ('0' + launchDateTime.getMinutes()).slice(-2);
		const Day = ('0' + launchDateTime.getDate()).slice(-2);
		const Month = ('0' + (launchDateTime.getMonth() + 1)).slice(-2);
		const Year = launchDateTime.getFullYear();
		const launchTime = `${Hour12}:${Minute} ${ampm}, ${Day}/${Month}/${Year}`;
		return (
			<div className='challenge__view__page'>
				<h1 className='challenge__view__name'>{challengeName}</h1>
				<p className='challenge__view__description challenge__view__description__coming__soon'>
					{challengeDesc}
				</p>
				<p className='challenge__view__description challenge__view__description__coming__soon'>
					{`This challenge starts on ${launchTime}`}
				</p>
			</div>
		);
	}

	return (
		<div className='challenge__view__page'>
			<h1 className='challenge__view__name'>{challengeName}</h1>

			<PointsModal
				challengePoints={challengePoints}
				submitOrderBonus={submitOrderBonus}
				timeHourlyBonus={timeHourlyBonus}
			/>

			<p className='challenge__view__description'>
				{challengeDesc?.split('\n')?.map((line) => {
					return (
						<>
							{line}
							<br />
						</>
					);
				})}
			</p>

			<StaticDynamic
				challengeType={challengeType}
				staticUrl={staticUrl}
				instanceFetching={instanceFetching}
				instanceError={instanceError}
				instanceStatus={instanceStatus}
				instanceIp={instanceIp}
				instanceCreationTime={instanceCreationTime}
				instanceLastUsedTime={instanceLastUsedTime}
				heartBeatInstance={heartBeatInstance}
				getInstanceInfo={getInstanceInfo}
				createInstance={createInstance}
				deleteInstance={deleteInstance}
			/>

			<Hints hints={hints} />
			<SubmitFlag
				submittedFlag={submittedFlag}
				myChallengePoint={myChallengePoint}
				myTimeBonusPoint={myTimeBonusPoint}
				mySubmissionOrderBonusPoint={mySubmissionOrderBonusPoint}
				submittingFlag={submittingFlag}
				submitFlag={submitFlag}
			/>
		</div>
	);
}
