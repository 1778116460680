import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ChallengePage, MainPage, RegisterPage } from './pages';
import { UserState } from './contexts/user/UserState';
import { ApiState } from './contexts/api/ApiState';
import ScrollToTop from './utils/ScrollToTop';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

function App() {
  return (
    <div className="App">
      <ApiState>
        <UserState>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/register" element={
                <ProtectedRoute>
                  <RegisterPage />
                </ProtectedRoute>
              } />

              <Route path="/challenge/*" element={
                <ChallengePage />
              } />

              <Route path='*' element={<Navigate to={'/'} replace={true} />} />


            </Routes>
          </BrowserRouter>
        </UserState>
      </ApiState>
    </div >
  );
}

export default App;
