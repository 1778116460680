import { createContext } from 'react';

const UserContext = createContext({
	userData: {
		id: 0,
		loggedIn: false,
		name: '',
		email: '',
		profilePictureUrl: '',
		role: [],
	},
	userLoading: true,
	userError: '',
	logout: () => {},
});

export default UserContext;
