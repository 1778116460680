import { useState } from 'react';
import { ModalPopup } from '../../ModalPopup/ModalPopup';
import './PointsModal.css';
import { withOrdinal_suffix_of } from '../../../utils/numberHelper';
export default function PointsModal({
	challengePoints,
	submitOrderBonus,
	timeHourlyBonus,
}) {
	const [pointModalOpen, setPointModalOpen] = useState(false);
	function SubmitOrderContainer() {
		if (Array.isArray(submitOrderBonus) && submitOrderBonus.length > 0) {
			return (
				<li className='points__modal__container__subtitle'>
					<h4 >
						Submit Order Bonus
					</h4>

					<ul>
						{submitOrderBonus.map((bonus, index) => {
							return (
								<li key={index}>
									{`Be the ${withOrdinal_suffix_of(
										index + 1
									)} solver: ${bonus} points`}
								</li>
							);
						})}
					</ul>
				</li>
			);
		}
		return <></>;
	}

	function TimeBonusContainer() {
		if (Array.isArray(timeHourlyBonus) && timeHourlyBonus.length > 0) {
			return (
				<li className='points__modal__container__subtitle'>
					<h4>
						Time Bonus
					</h4>
					<ul>
						{timeHourlyBonus.map((bonus, index) => {
							return (
								<li key={index}>
									{`Solve within ${withOrdinal_suffix_of(
										index + 1
									)} hour: ${bonus} points`}
								</li>
							);
						})}
					</ul>
				</li>
			);
		}
		return <></>;
	}

	const minPoints = challengePoints;
	let maxPoints = challengePoints;
	if (Array.isArray(submitOrderBonus) && submitOrderBonus.length > 0) {
		maxPoints += submitOrderBonus[0];
	}

	if (Array.isArray(timeHourlyBonus) && timeHourlyBonus.length > 0) {
		maxPoints += timeHourlyBonus[0];
	}

	return (
		<>
			<span
				className='points__summary'
				onClick={() => {
					setPointModalOpen(true);
				}}
			>
				{minPoints} - {maxPoints} Pts
			</span>
			<ModalPopup open={pointModalOpen} setOpen={setPointModalOpen}>
				<div className='points__modal__container'>
					<h2 className='points__modal__container__title'>
						Point Distribution
					</h2>
					<ul>
						<li className='points__modal__container__subtitle'>
							<h3>Challenge Points: {challengePoints}</h3>
						</li>
						<SubmitOrderContainer />
						<TimeBonusContainer />
					</ul>
				</div>
			</ModalPopup>
		</>
	);
}
