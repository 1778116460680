import './Instructions.css';

export default function Instructions() {
	return (
		<div className='instructions__wrapper'>
			<div className='instructions__header'>
				<h1>Instructions</h1>
			</div>
			<div className='instructions__body'>
				<p>
					Welcome to CyberQuest. This is a CTF (Capture the Flag)
					Competition.
				</p>
				<p>
					There will be a secret flag somewhere hidden in the
					challenges. Your task is to find them and submit them. Flags
					will be of the format{' '}
					<span className='instruction__highlight'>{`cyberQuest{somevalue}`}</span>
					. Each challenge will have a name and description with hints
					on how to find the flags. Each challenge has a certain
					amount of points assigned to them. People who submit flags
					first will get bonus points.
				</p>

				<p>Good luck. Have fun.</p>
			</div>
		</div>
	);
}
