import { useEffect, useState } from 'react';
import UserContext from './UserContext';
import { ApiContext } from '../api/ApiContext';
import { useContext } from 'react';
import { jwtDecode } from 'jwt-decode';

export function UserState({ children }) {
	const { accessToken, refreshToken } = useContext(ApiContext);

	const [userLoading, setUserLoading] = useState(true);
	const [userError, setUserError] = useState('');
	const [userData, setUserData] = useState({
		id: 0,
		loggedIn: false,
		name: '',
		email: '',
		profilePictureUrl: '',
		role: [],
	});

	async function fetchUserData() {
		try {
			setUserLoading(true);
			if (refreshToken && accessToken) {
				const userProfile = jwtDecode(accessToken);
				setUserData((userData) => {
					return {
						...userData,
						email: userProfile.email,
						name: userProfile.name,
						profilePictureUrl: userProfile.picture,
						loggedIn: true,
						role: userProfile.role,
						id: userProfile.user_id,
					};
				});
				setUserLoading(false);
			} else if (refreshToken && !accessToken) {
				setUserData((userData) => {
					return {
						loggedIn: false,
						name: '',
						email: '',
						profilePictureUrl: '',
						role: [],
						id: 0,
					};
				});
				setUserLoading(true);
			} else {
				setUserData((userData) => {
					return {
						loggedIn: false,
						name: '',
						email: '',
						profilePictureUrl: '',
						role: [],
						id: 0,
					};
				});
				setUserLoading(false);
			}
		} catch (err) {
			setUserError('Error fetching user data');
			console.log(err);
			setUserLoading(false);
		} finally {
		}
	}

	useEffect(() => {
		fetchUserData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken, refreshToken]);

	function logout() {
		setUserLoading(true);
		setUserData({
			loggedIn: false,
			name: '',
			email: '',
			profilePictureUrl: '',
			role: [],
			id: 0,
		});
		setUserError('');
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		setUserLoading(false);
	}

	return (
		<UserContext.Provider
			value={{ userData, userLoading, userError, logout }}
		>
			{children}
		</UserContext.Provider>
	);
}
