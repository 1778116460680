import { useState } from 'react';
import { ModalPopup } from '../../ModalPopup/ModalPopup';
import './SubmitFlag.css';

export default function SubmitFlag({
	submittedFlag,
	myChallengePoint,
	myTimeBonusPoint,
	mySubmissionOrderBonusPoint,
	submittingFlag,
	submitFlag,
}) {
	const [showModal, setShowModal] = useState(false);
	const [flagValue, setFlagValue] = useState('');
	if (submittedFlag) {
		return (
			<div className='submitted__flag__container'>
				<h3>Congrats! You have solved this challenge!</h3>
				<h4
					className='submit__flag__button'
					onClick={() => {
						setShowModal(true);
					}}
				>
					Points Earned:{' '}
					{myChallengePoint +
						mySubmissionOrderBonusPoint +
						myTimeBonusPoint}
				</h4>

				<ModalPopup open={showModal} setOpen={setShowModal}>
					<div className='modal__popup__container'>
						<h2>Points Breakdown</h2>
						<p>Challenge Points: {myChallengePoint}</p>
						{mySubmissionOrderBonusPoint > 0 && (
							<p>
								Submission Order Bonus:{' '}
								{mySubmissionOrderBonusPoint}
							</p>
						)}
						{myTimeBonusPoint > 0 && (
							<p>Time Bonus: {myTimeBonusPoint}</p>
						)}
					</div>
				</ModalPopup>
			</div>
		);
	}

	return (
		<div className='submit__flag__container'>
			<input
				className='submit__flag__input'
				placeholder='cyberQuest{Flag}'
				value={flagValue}
				onChange={(e) => setFlagValue(e.target.value)}
			/>
			<button
				className='submit__flag__button'
				disabled={submittingFlag}
				onClick={() => {
					submitFlag(flagValue);
				}}
			>
				Submit Flag
			</button>
		</div>
	);
}
