
import React from 'react';
import './ChallengeFormat.css';

export default function ChallengeFormat() {
    return (
        <div className='challenge__format__container'>
            <h2 data-aos="fade-up">CHALLENGE FORMAT</h2>
            <p data-aos="fade-up">
                CyberQuest is a free for all capture the flag competition. There will be a secret 
                flag somewhere hidden in the challenges. Your task is to find them and submit them.
                Each challenge will have an associated point value. The first person to submit a flag
                will get extra bonus points. Top 3 people from the leaderboard will get the prizes.
            </p>
            <p data-aos="fade-up">
                So what are you waiting for? Get started and rush to the top of the leaderboard!
            </p>
            {/* <p data-aos="fade-up">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique quis commodi animi atque, qui sint quae cum iste rerum expedita ab! Dolorum, possimus? Harum nobis enim ex aut ipsam temporibus?
            </p> */}
        </div>
    );
}