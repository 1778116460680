import { useState } from 'react';
import './Hints.css';

export default function Hints({ hints }) {
	const [openHint, setOpenHint] = useState(0);
	if (!Array.isArray(hints)) {
		console.error('Hints is not an array');
		return <></>;
	}

	if (hints.length === 0) {
		return <></>;
	}

	return (
		<div className='hints__container'>
			<div className='hints__header'>
				{hints.map((hint, index) => {
					return (
						<div
							className={`hints__header__tab ${
								openHint === index ? 'active' : ''
							}`}
							onClick={() => setOpenHint(index)}
							key={index}
						>
							{`Hint ${index + 1}`}
						</div>
					);
				})}
			</div>
			<div className='hints__body'>
				{hints[openHint]?.value?.split('\n')?.map((line) => {
					return (
						<>
							{line}
							<br />
						</>
					);
				})}
			</div>
		</div>
	);
}
