import { useContext, useState } from 'react';
import { ApiContext } from '../contexts/api/ApiContext';
import { getErrMsg } from './errorParser';

export function useRegister() {
	const [launchDateTime, setLaunchDateTime] = useState(
		new Date('3000-01-01Z00:00:00:000')
	);
	const [endDateTime, setEndDateTime] = useState(
		new Date('3000-01-01Z00:00:00:000')
	);
    
    const [launched, setLaunched] = useState(false);
    const [finished, setFinished] = useState(false);
    const [registered, setRegistered] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const { axiosCyberQuestPrivate } = useContext(ApiContext);


	async function fetchIfRegistered() {
		try {
			setLoading(true);
			setError('');

			const registerRes = await axiosCyberQuestPrivate.get('/register');

            setLaunchDateTime(new Date(registerRes.data?.launchDateTime));
            setEndDateTime(new Date(registerRes.data?.endDateTime));
            setRegistered(registerRes.data?.registered);
            setLaunched(registerRes.data?.launched);
            setFinished(registerRes.data?.finished);

		} catch (error) {
            setError(getErrMsg(error));
		} finally {
			setLoading(false);
		}
	}

    async function register() {
		try {
			setLoading(true);
			setError('');

			const registerRes = await axiosCyberQuestPrivate.post('/register');

            setLaunchDateTime(new Date(registerRes.data?.launchDateTime));
            setEndDateTime(new Date(registerRes.data?.endDateTime));
            setRegistered(registerRes.data?.registered);
            setLaunched(registerRes.data?.launched);
            setFinished(registerRes.data?.finished);

		} catch (error) {
            setError(getErrMsg(error));
		} finally {
			setLoading(false);
		}
	}

    return {
        launchDateTime,
        endDateTime,
        launched,
        finished,
        registered,
        loading,
        error,
        fetchIfRegistered,
        register
    }
}
