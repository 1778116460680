import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../contexts/api/ApiContext';
import { getErrMsg } from '../errorParser';
import { useInterval } from 'usehooks-ts';

export const instanceStatuses = {
	NOT_CREATED: 'NOT_CREATED',
	STARTING: 'STARTING',
	RUNNING: 'RUNNING',
	STOPPING: 'STOPPING',
	ERRORED: 'ERRORED',
	STOPPING_INACTIVE: 'STOPPING_INACTIVE',
	TERMINATED_INACTIVE: 'TERMINATED_INACTIVE',
};

export const challengeTypes = {
	STATIC: 'STATIC',
	DYNAMIC: 'DYNAMIC',
};

export function useChallenge(challengeId) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const [launchDateTime, setLaunchDateTime] = useState(
		new Date('3000-01-01Z00:00:00:000')
	);
	const [challengeName, setChallengeName] = useState('');
	const [challengeDesc, setChallengeDesc] = useState('');
	const [challengeType, setChallengeType] = useState('');

	const [challengePoints, setChallengePoints] = useState(0);
	const [submitOrderBonus, setSubmitOrderBonus] = useState([]);
	const [timeHourlyBonus, setTimeHourlyBonus] = useState([]);

	const [submittedFlag, setSubmittedFlag] = useState(false);
	const [myChallengePoint, setMyChallengePoint] = useState(0);
	const [myTimeBonusPoint, setMyTimeBonusPoint] = useState(0);
	const [mySubmissionOrderBonusPoint, setMySubmissionOrderBonusPoint] =
		useState(0);
	const [submittingFlag, setSubmittingFlag] = useState(false);

	const [hints, setHints] = useState([{}]);

	// Will be set for STATIC challenges
	const [staticUrl, setStaticUrl] = useState('');

	// For DYNAMIC challenges
	const [instanceShouldPoll, setInstanceShouldPoll] = useState(false);
	const [instanceFetching, setInstanceFetching] = useState(true);
	const [instanceError, setInstanceError] = useState('');
	const [instanceStatus, setInstanceStatus] = useState(
		instanceStatuses.NOT_CREATED
	);
	const [instanceIp, setInstanceIp] = useState('');

	const [instanceCreationTime, setInstanceCreationTime] = useState(
		new Date('3000-01-01Z00:00:00:000')
	);
	const [instanceLastUsedTime, setInstanceLastUsedTime] = useState(
		new Date('3000-01-01Z00:00:00:000')
	);

	const { axiosCyberQuestPrivate } = useContext(ApiContext);

	async function getChallengeInfo() {
		try {
			setLoading(true);
			setError('');

			const { data } = await axiosCyberQuestPrivate.get(
				`/challenge/${challengeId}`
			);

			setLaunchDateTime(new Date(data.launchDateTime));
			setChallengeName(data.name);
			setChallengeDesc(data.description);
			setChallengeType(data.type);

			setHints(data.hints);

			setChallengePoints(data.challengePoints);
			setSubmitOrderBonus(data.submitOrderBonus);
			setTimeHourlyBonus(data.timeHourlyBonus);

			if (data.staticUrl) {
				setStaticUrl(data.staticUrl);
			}

			const submitRes = await axiosCyberQuestPrivate.get(
				`/challenge/${challengeId}/submission`
			);

			if (submitRes.data?.submission?.submissionDateTime) {
				setSubmittedFlag(true);
				setMyChallengePoint(submitRes.data.submission.challengePoint);
				setMyTimeBonusPoint(submitRes.data.submission.timeBonusPoint);
				setMySubmissionOrderBonusPoint(
					submitRes.data.submission.submissionOrderBonusPoint
				);
			} else {
				setSubmittedFlag(false);
				setMyChallengePoint(0);
				setMyTimeBonusPoint(0);
				setMySubmissionOrderBonusPoint(0);
			}
		} catch (error) {
			setError(getErrMsg(error));
		} finally {
			setLoading(false);
		}
	}

	async function getInstanceInfo(setInstanceLoading = true) {
		try {
			if (setInstanceLoading) {
				setInstanceFetching(true);
			}
			setInstanceError('');

			const { data } = await axiosCyberQuestPrivate.get(
				`/challenge/${challengeId}/instance`
			);
			setInstanceStatus(data.status);
			setInstanceIp(data.ip);
			setInstanceCreationTime(new Date(data.creationDateTime));
			setInstanceLastUsedTime(new Date(data.lastUsedDateTime));
			return data.status;
		} catch (error) {
			if (error?.response?.status === 404) {
				setInstanceError('');
				setInstanceIp('');
				setInstanceStatus(instanceStatuses.NOT_CREATED);
				setInstanceCreationTime(new Date('3000-01-01Z00:00:00:000'));
				setInstanceLastUsedTime(new Date('3000-01-01Z00:00:00:000'));
			} else {
				setInstanceError(getErrMsg(error));
			}
			return '';
		} finally {
			if (setInstanceLoading) {
				setInstanceFetching(false);
			}
		}
	}

	async function createInstance() {
		try {
			setInstanceFetching(true);
			setInstanceError('');

			await axiosCyberQuestPrivate.post(
				`/challenge/${challengeId}/instance`
			);
			await getInstanceInfo();
		} catch (error) {
			if (error?.response?.status === 400) {
				alert(error.response.data.error);
			} else {
				setInstanceError(getErrMsg(error));
			}
		} finally {
			setInstanceFetching(false);
		}
	}

	async function heartBeatInstance(captchaValue) {
		try {
			setInstanceFetching(true);
			setInstanceError('');

			await axiosCyberQuestPrivate.post(
				`/challenge/${challengeId}/heartbeat`,
				{
					captchaValue: captchaValue,
				}
			);
			await getInstanceInfo();
		} catch (error) {
			setInstanceError(getErrMsg(error));
		} finally {
			setInstanceFetching(false);
		}
	}

	async function deleteInstance() {
		try {
			setInstanceFetching(true);
			setInstanceError('');

			await axiosCyberQuestPrivate.delete(
				`/challenge/${challengeId}/instance`
			);
			await getInstanceInfo();
		} catch (error) {
			setInstanceError(getErrMsg(error));
		} finally {
			setInstanceFetching(false);
		}
	}

	// async function pollInstanceStatus() {
	// 	// await new Promise((resolve) => setTimeout(resolve, 1000));
	// 	const status = await getInstanceInfo(false);
	// 	if (
	// 		status === instanceStatuses.STARTING ||
	// 		status === instanceStatuses.STOPPING ||
	// 		status === instanceStatuses.STOPPING_INACTIVE
	// 	) {
	// 		// setTimeout(pollInstanceStatus, 1000);
	// 	}
	// }

	async function submitFlag(flagValue) {
		try {
			setSubmittingFlag(true);
			setError('');

			const { data } = await axiosCyberQuestPrivate.post(
				`/challenge/${challengeId}/submission`,
				{
					flag: flagValue.trim(),
				}
			);

			if (data?.submission?.submissionDateTime) {
				alert('Flag submitted successfully!');

				window?.location?.reload();
			} else {
				alert('Flag submission failed!');
			}

			await getChallengeInfo();
		} catch (error) {
			const response = error?.response;
			if (response?.status === 400) {
				if (response?.data?.error) {
					alert(response.data.error);
				} else {
					alert('Invalid flag!');
				}
			} else {
				setError(getErrMsg(error));
			}
		} finally {
			setSubmittingFlag(false);
		}
	}

	useEffect(() => {
		if (
			instanceStatus === instanceStatuses.STARTING ||
			instanceStatus === instanceStatuses.STOPPING ||
			instanceStatus === instanceStatuses.STOPPING_INACTIVE
		) {
			setInstanceShouldPoll(true);
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instanceStatus]);
	
	useInterval(
		async () => {
			const status = await getInstanceInfo(false);
			if (
				status === instanceStatuses.STARTING ||
				status === instanceStatuses.STOPPING ||
				status === instanceStatuses.STOPPING_INACTIVE
			) {
				setInstanceShouldPoll(true);
			} else {
				setInstanceShouldPoll(false);
			}
		},
		instanceShouldPoll ? 4000 : null
	);


	return {
		loading,
		error,
		launchDateTime,
		challengeName,
		challengeDesc,
		challengeType,
		hints,

		challengePoints,
		submitOrderBonus,
		timeHourlyBonus,

		submittedFlag,
		myChallengePoint,
		myTimeBonusPoint,
		mySubmissionOrderBonusPoint,
		submittingFlag,
		submitFlag,

		staticUrl,

		instanceFetching,
		instanceError,
		instanceStatus,
		instanceIp,
		instanceCreationTime,
		instanceLastUsedTime,

		heartBeatInstance,
		getChallengeInfo,
		getInstanceInfo,
		createInstance,
		deleteInstance,
	};
}
