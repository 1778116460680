import './ModalPopup.css';

export function ModalPopup({ children, open, setOpen }) {
	return (
		<>
			<div className={`modal__popup ${open ? 'modal_visible' : ''}`}>
				{children}
			</div>
			<div
				className={`modal__popup__overlay ${
					open ? 'modal_visible' : ''
				}`}
				onClick={() => setOpen(false)}
			></div>
		</>
	);
}
