import { useContext, useState } from 'react';
import { ApiContext } from '../../contexts/api/ApiContext';
import { getErrMsg } from '../errorParser';
import UserContext from '../../contexts/user/UserContext';

export function useMySubmissions() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [mySubmissions, setMySubmissions] = useState([]);

	const { axiosCyberQuestPrivate } = useContext(ApiContext);
	const { userData } = useContext(UserContext);

	async function getMySubmissions() {
		try {
			setLoading(true);
			setMySubmissions(new Array(0));

			if (!userData?.loggedIn) {
				return;
			}

			const { data } = await axiosCyberQuestPrivate.get(
				`/challenge/mysubmissions`
			);
			const mySubmittedChallenges = new Array(0);
			data?.submissions?.forEach((submission) => {
				mySubmittedChallenges.push(submission.challengeId);
			});

			setMySubmissions(mySubmittedChallenges);
		} catch (error) {
			setError(getErrMsg(error));
		} finally {
			setLoading(false);
		}
	}

	return {
		loading,
		error,
		mySubmissions,
		getMySubmissions,
	};
}
