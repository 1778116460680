// Hero.js
import './Hero.css';
import React from 'react';
import excelLogo from '../../../assets/png/excel_logo.png';
import IEDCLogo from '../../../assets/png/IEDCLogo.png';
import { Link } from 'react-router-dom';

export default function Hero() {
	return (
		<div className='hero_wrapper'>
			<div className='navbar__container'>
				<div className='navbar__main_body'>
					<a
						href='https://www.excelmec.org/'
						target='_blank'
						rel='noreferrer'
					>
						<img className='excel_logo' src={excelLogo} alt='' />
					</a>
					<p className='and__txt'>AND</p>
					<a
						href='https://www.iedcmec.in/'
						target='_blank'
						rel='noreferrer'
					>
						<img className='iedc_logo' src={IEDCLogo} alt='' />
					</a>
				</div>
				<p className='presents__txt'>Present</p>
			</div>
			<div className='main__page_container'>
				<div className='main__text_container'>
					<h1 className='evnt__name_quest1'>Cyber Quest</h1>
					<h1 className='evnt__name_quest2'>CTF Challenge</h1>
					<h2>Dec 6th to 20th</h2>
				</div>
				<Link className='reg__btn' to='/challenge'>
					Go to Challenge
				</Link>
			</div>
		</div>
	);
}
