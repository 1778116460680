import './SideBar.css';
import { NavLink } from 'react-router-dom';
import { challengeIds } from '../../../hooks/challenges/challengeIds';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/user/UserContext';
import { login } from '../../ProtectedRoute/ProtectedRoute';
import logoutImg from '../../../assets/png/logout.png';
import { useMySubmissions } from '../../../hooks/challenges/useSubmissions';
import { gameOver } from '../../../utils/gameOver';

export default function SideBar() {
	

	const { userData, userLoading, userError, logout } =
		useContext(UserContext);

	const { mySubmissions, getMySubmissions, loading } = useMySubmissions();

	const [sidebarOpen, setSidebarOpen] = useState(false);

	function SideBarUserContainer() {
		if (userLoading) {
			return 'Loading...';
		} else if (userError) {
			console.error(userError);
			return 'Error loading user data';
		} else if (!userData) {
			return 'Error loading user data';
		} else if (!userData.loggedIn) {
			return (
				<button className='sidebar__btn' onClick={login}>
					Login
				</button>
			);
		} else {
			return (
				<>
					<img
						className='sidebar__user__icon'
						src={userData.profilePictureUrl}
						alt='profileImg'
					/>
					<div className='sidebar__user__name'>{userData.name}</div>
					<img
						src={logoutImg}
						alt='logout'
						onClick={logout}
						className='sidebar__user__logout'
						rel='noreferrer noopener'
					/>
				</>
			);
		}
	}

	useEffect(() => {
		if (!userLoading && !gameOver) {
			getMySubmissions();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData, userLoading, userData?.loggedIn]);

	return (
		<>
			<div
				className='sidebar__hamburger'
				onClick={() => setSidebarOpen(!sidebarOpen)}
			>
				{sidebarOpen ? <CloseIcon /> : <MenuIcon />}
			</div>
			<div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
				<div className='sidebar__header'>CYBER QUEST</div>
				<div className='sidebar__header__spacer'></div>

				{!gameOver && (
					<NavLink
						onClick={() => {
							setSidebarOpen(false);
						}}
						to={'/challenge/instructions'}
						className='sidebar__link'
					>
						Instructions
						<div className='sidebar__check__empty'></div>
					</NavLink>
				)}

				{gameOver && (
					<NavLink
						onClick={() => {
							setSidebarOpen(false);
						}}
						to={'/challenge/winners'}
						className='sidebar__link'
					>
						Winners
						<div className='sidebar__check__empty'></div>
					</NavLink>
				)}

				<NavLink
					onClick={() => {
						setSidebarOpen(false);
					}}
					to={'/challenge/leaderboard'}
					className='sidebar__link'
				>
					Leaderboard
					<div className='sidebar__check__empty'></div>
				</NavLink>

				{!gameOver &&
					challengeIds.map((challengeId) => {
						return (
							<NavLink
								onClick={() => {
									setSidebarOpen(false);
								}}
								key={challengeId}
								to={`/challenge/${challengeId.toString()}`}
								className='sidebar__link'
							>
								Challenge {challengeId}{' '}
								{!loading &&
								mySubmissions?.find(
									(submittedChallengeId) =>
										submittedChallengeId === challengeId
								) ? (
									<>
										<div className='tooltip'>
											<CheckIcon />
											<span className='tooltiptext'>
												Submitted successfully
											</span>
										</div>
									</>
								) : (
									<div className='sidebar__check__empty'></div>
								)}
							</NavLink>
						);
					})}

				<div className='sidebar__user__spacer'></div>
				<div className='sidebar__user__container'>
					<SideBarUserContainer />
				</div>
			</div>

			<div
				className={`sidebar__overlay ${sidebarOpen ? 'open' : ''}`}
				onClick={() => setSidebarOpen(!sidebarOpen)}
			></div>
		</>
	);
}

function MenuIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='25'
			height='25'
			viewBox='0 0 50 50'
		>
			<path d='M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 L 0 7.5 z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 L 0 22.5 z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 L 0 37.5 z'></path>
		</svg>
	);
}

function CloseIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='25'
			height='25'
			viewBox='0 0 50 50'
		>
			<path d='M 40.783203 7.2714844 A 2.0002 2.0002 0 0 0 39.386719 7.8867188 L 25.050781 22.222656 L 10.714844 7.8867188 A 2.0002 2.0002 0 0 0 9.2792969 7.2792969 A 2.0002 2.0002 0 0 0 7.8867188 10.714844 L 22.222656 25.050781 L 7.8867188 39.386719 A 2.0002 2.0002 0 1 0 10.714844 42.214844 L 25.050781 27.878906 L 39.386719 42.214844 A 2.0002 2.0002 0 1 0 42.214844 39.386719 L 27.878906 25.050781 L 42.214844 10.714844 A 2.0002 2.0002 0 0 0 40.783203 7.2714844 z'></path>
		</svg>
	);
}

function CheckIcon() {
	return (
		<svg
			className='sidebar__check'
			focusable='false'
			aria-hidden='true'
			viewBox='0 0 24 24'
			data-testid='TaskAltIcon'
		>
			<path
				fill='currentColor'
				d='M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10L22 5.18zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44C16.1 2.67 14.13 2 12 2 6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39l-1.61 1.61z'
			></path>
		</svg>
	);
}
